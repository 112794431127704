@font-face {
  font-family: "FixelDisplayThin";
  src: url("./assets/fonts/FixelDisplay-Thin.ttf");
}
@font-face {
  font-family: "OutfitMedium";
  src: url("./assets/fonts/Outfit-Medium.ttf");
}
@font-face {
  font-family: "UrbanistMedium";
  src: url("./assets/fonts/Urbanist-Medium.ttf");
}

.bgWebApp {
  background-color: #f7fcff;
}
.webPage_box4 {
  background-image: url("./assets/images/services/Web_BG1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.webPage_p {
  color: #a4b3cb;
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 12px;
}
.webPage_p1 {
  color: #a4b3cb;
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 11px;
  text-align: end;
}
.webPage_p2 {
  color: #2b2b2b;
  font-family: ManropeRegular;
  font-weight: 600;
  font-size: 11px;
  text-align: end;
}
.webPage_btn {
  background: #254c8d;
  color: white;
  border: 1px solid white;
  border-radius: 20px;
  font-family: ManropeRegular;
  font-weight: 700;
  font-size: 13px;
}
.webPage_btn:hover {
  background: white;
  color: #254c8d;
  border: 1px solid #254c8d;
}
.webPage_btn1 {
  background: transparent;
  color: #2b2b2b;
  border: 1px solid #2b2b2b;
  border-radius: 20px;
  font-family: ManropeRegular;
  font-weight: 700;
  font-size: 13px;
}
.WebBGDevelop {
  margin-left: -16.5%;
}
.webPage_btn1:hover {
  background: #2b2b2b;
  color: #fff;
  border: 1px solid #2b2b2b;
  border-radius: 20px;
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 13px;
}
.webPage_box {
  top: 39%;
  left: 15%;
}
.webPage_box1 {
  top: 64%;
  right: 10%;
}
.webPage_box2 {
  margin-left: 32%;
}
.webPage_box6 {
  margin-left: 24%;
}
.webPage_box7 {
  margin-left: 39%;
}
.webPage_h2 {
  font-family: UrbanistReg;
  font-weight: 400;
}
.webPage_h6 {
  font-family: UrbanistBold;
  font-weight: 400;
}
.webPage_h6_1 {
  font-family: UrbanistBold;
  font-weight: 500;
  font-size: 15px;
}
.webPage_h5 {
  font-family: UrbanistSemiBold;
  font-weight: 400;
}
.webPage_p3 {
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 13px;
  color: #2b2b2b;
}
.webPage_p8 {
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 11px;
  color: #2b2b2b;
}
.webPage_p6 {
  font-family: ManropeBold;
  font-weight: 400;
  font-size: 12px;
  color: #a5a5a5;
}
.webPage_p4 {
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 10px;
  color: #2b2b2b;
}
.webPage_p7 {
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 11px;
  color: #2b2b2b;
}
.webPage_box3 {
  margin-left: 15%;
  margin-right: 15%;
}
.webPage_box5 {
  margin-left: 15%;
  margin-right: 15%;
}
.webPage_p5 {
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 12px;
}
.WebGlobe {
  top: 14%;
  right: 0%;
}
.WebAttendify {
  top: 20%;
  right: 0%;
}
.WebAttendifyImg {
  width: 350px;
}
.accordion {
  width: 100%;
}
.StaffAugmentationProcessFlow{
  margin-left: 16.3%;
  margin-right: 13%;
}




.has-float-label {
  position: relative;
  border: 1px solid #333333;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 2rem;
  
}
.has-float-label > span {
  position: absolute;
  top: -0.75rem; 
  left: 0.75rem; 
  background: #F8F8F8;
  padding: 0 0.25rem;
}
.has-float-label > .content {
  margin-top: 0.5rem; 
  text-align: start;
}
.mainCardEnhanceTeam{
  margin-left: 16%;
  margin-right: 14%;
}
.AugmentationEnhanceTeam{
  background-color: #F4F7FE;
}
.augmentationSendEmailInput {
  padding: 6.5px 0px 6.7px 14px;
  width: 37% !important;
  border-radius: 4px;
  border: 1px solid #A3A3A3;
}
.augmentSendEmailBtn {
  background-color: #4659a7;
  color: #fff;
  border: none;
  padding: 7px 20px 7px 20px;
  border: 1px solid #4659a7;
  border-radius: 4px;
}
.augmentGetStartedBtn {
  background-color: #4659a7;
  color: #fff;
  border: none;
  padding: 9px;
  border: 1px solid #4659a7;
  border-radius: 4px;
}
.requestConsultBtn {
  color: #4659a7;
  background-color: transparent;
  border: none;
  padding: 9px;
  border: 1px solid #4659a7;
  border-radius: 4px;
}

.accordion-item {
  border-bottom: 1.33px solid #000000 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  width: 100%;
  padding-left: 2px;
  border-radius: 1px !important;
  border: none;
  background-color: transparent !important;
}
.accordion-itemactive {
  border-bottom: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  width: 100%;
  padding-left: 2px;
  border-radius: 1px !important;
  border: none;
  background-color: transparent !important;
}

.accordion-title {
  padding: 10px;
  padding-left: 0rem !important;
  cursor: pointer;
  color: #000000;
  font-family: ManropeBold;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7fcff;
}
.accordion-content {
  display: none;
}
.accordion-title.active {
  background-color: #f7fcff;
}
.accordion-content.active {
  display: block;
  padding-left: 1rem;
  color: #000000;
  font-family: ManropeRegular;
  font-weight: 500;
  font-size: 10.5px;
  background-color: #f7fcff;
}
.mobile_box_97 {
  height: 150px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .webPage_p {
    font-size: 10px;
  }
  .webPage_p1 {
    font-size: 10px;
  }
  .webPage_btn {
    font-size: 10px;
  }
  .WebAttendifyImg {
    width: 250px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .webPage_box8 {
    margin-top: 20% !important;
  }
}
@media (max-width: 768px) {
  .webPage_box {
    top: 100%;
    left: 5%;
  }
  .webPage_box1 {
    display: none;
  }
  .webPage_p1 {
    font-size: 14px;
    text-align: center;
  }
  .webPage_box2 {
    margin-left: 5%;
  }
  .webPage_box3 {
    margin-left: 3%;
    margin-right: 3%;
  }
  .webPage_h2 {
    font-size: 23px;
  }
  .webPage_p3 {
    font-size: 10px;
  }
  .WebAttendifyImg {
    display: none;
  }
  .WebGlobe {
    display: none;
  }
  .webPage_box5 {
    margin-left: 5%;
    margin-right: 5%;
  }
  .mobile_box_97 {
    height: 200px;
  }
  
}

@media (max-width: 576px) {
  .webPage_box {
    top: 100%;
    left: 5%;
  }
  .webPage_p1 {
    font-size: 14px;
    text-align: center;
  }
  .webPage_box2 {
    margin-left: 5%;
  }
  .webPage_h2 {
    font-size: 18px;
    text-align: center;
    margin-top: 5px;
  }
  .webPage_p3 {
    text-align: center;
  }
  .webPage_box6 {
    margin-left: 5%;
  }
  .webPage_box7 {
    margin-left: 5%;
  }
  .webPage_p8 {
    text-align: center;
    font-size: 10px;
  }
  .accordion-title {
    font-size: 10px;
    padding: 8px;
  }
  .accordion-content {
    font-size: 10px;
    padding: 8px;
  }
  .WebAttendifyImg {
    display: block;
    width: 250px;
  }
  .WebAttendify {
    top: 100%;
    right: 0%;
  }
  .webPage_box8 {
    margin-top: 45% !important;
  }
  .webPage_box9 {
    margin-top: 70% !important;
  }
  .augmentationHeroImage img {
    width: 100% !important;
    height: 64%;
  }
  .augmentation_box {
    margin-left: 6% !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .augmentationSendEmailInput {
    padding: 6.5px 0px 6.7px 14px;
    width: 57% !important;
    border-radius: 4px;
    border: 1px solid #A3A3A3;
  }
}

.logo-slider-container {
  overflow: hidden;
  width: 100%;
  height: 100px;
}
.logo-slider {
  display: flex;
  animation: slide-left-right-left 10s linear infinite;
}
@keyframes slide-left-right-left {
  0% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(50);
  }
  75% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.logo-slider img {
  flex-shrink: 0;
  width: 80px;
  height: auto;
  margin: 0 20px;
  transition: opacity 0.5s ease-in-out;
}
.logo-slider img:hover {
  opacity: 1;
}
@media (max-width: 576px) {
  .logo-slider-container {
    width: 100%;
  }
  .logo-slider img {
    margin: 0 10px;
    width: 65px;
  }
}

.meta_main_color {
  background-color: #0d0025;
}
.meta_main_bg {
  background-image: url("./assets/images/services/Meta_Bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 820px;
}
.meta_h1 {
  font-family: Questrial;
  font-weight: 400;
  font-size: 43px;
}
.Constilation01 {
  top: 10%;
  right: 10%;
}
.english-word {
  font-family: FixelDisplayThin;
  font-size: 154px;
  text-align: center;
  letter-spacing: 25px;
  font-weight: 300;
}
.english-word span {
  transition: color 0.3s, filter 0.3s;
  cursor: pointer;
}
.meta_box1 {
  margin-left: 33%;
}
.meta_box2 {
  margin-left: 15%;
  margin-right: 15%;
}
.meta_box3 {
  background-image: url("./assets/images/services/Meta_Bg_5.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.meta_box4 {
  background-image: url("./assets/images/services/Meta_BG_Last.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 170px;
  padding-bottom: 145px;
}
.meta_box5 {
  background-image: url("./assets/images/services/Meta_Bg_7.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.meta_p {
  font-family: RalewayRegular;
  font-weight: 600;
  background-color: #532c9e;
  display: inline-flex;
  box-shadow: 0px 4px 25px 0px #3c50e057;
}
.meta_h2 {
  font-family: Questrial;
  font-weight: 400;
}
.meta_h1_23 {
  font-family: Questrial;
  font-weight: 400;
}
.meta_p_1 {
  font-family: Roboto-Regular;
  font-weight: 400;
  color: #7c7589;
  font-size: 12px;
}
.meta_p_15 {
  font-family: Roboto-Regular;
  font-weight: 400;
  color: #7c7589;
  font-size: 12px;
}
.meta_btn {
  border-radius: 50px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-family: ManropeRegular;
  font-weight: 700;
}
.meta_btn:hover {
  background-color: white;
  color: #0d0025;
}
.meta_p_2 {
  font-family: InterLight;
  font-size: 11px;
  width: 100%;
  height: 50px;
  font-weight: 300;
  color: #a3a3a3;
}
.meta_p_3 {
  font-family: InterLight;
  font-size: 10.5px;
  font-weight: 300;
  color: #a3a3a3;
}
.meta_color_main {
  color: #36164e;
}
.meta_p_4 {
  font-family: OutfitMedium;
  font-size: 12px;
  font-weight: 500;
  color: #a09aa9;
}
.MetaIMG {
  width: 150px;
}
.MetaIMG3 {
  margin-top: -19%;
}
.MetaIMG3_Blur {
  background: #0d0025;
  width: 100%;
  height: 150px;
  filter: blur(54.6px);
  bottom: -8%;
}
.meta_small_slider_border {
  border: 2.5px solid;
  border-image-source: linear-gradient(
    138.34deg,
    rgba(224, 37, 206, 0.23) -11.36%,
    rgba(74, 237, 82, 0.23) 51.55%,
    rgba(189, 36, 230, 0.23) 113.16%
  );
  margin-left: -1% !important;
  border-image-slice: 1;
  background: linear-gradient(
    138.84deg,
    rgba(255, 255, 255, 0) -6.39%,
    rgba(143, 143, 143, 0) 112.17%
  );
}
.meta_small_slider {
  margin-left: 15%;
  margin-right: 15%;
}

.arrowbtn_meta_slider {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  background: linear-gradient(
    32.95deg,
    rgba(0, 255, 255, 0.21) -84.14%,
    rgba(0, 255, 255, 0) 105.06%
  );
  border: 1px solid #ffffff12;
  color: white;
  justify-content: center;
  text-align: center;
}
.arrowbtn_meta_slider.disabled {
  background: #0d0529;
  border: 1px solid #ffffff12;
  color: #27478c;
  pointer-events: none;
}
.arrowbtn_meta_slider.active {
  background: linear-gradient(
    32.95deg,
    rgba(0, 255, 255, 0.21) -84.14%,
    rgba(0, 255, 255, 0) 105.06%
  );
  color: white;
}
.activeBtnMeta {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: transparent;
  background: linear-gradient(
    55.36deg,
    #ec06c9 -1.94%,
    rgba(0, 255, 255, 0) 115.94%
  );
  color: white;
}
.meta_slider_mobileBTN {
  border: 2px solid #ffffff12;
  margin-top: 10px;
  background: linear-gradient(
    32.95deg,
    rgba(0, 255, 255, 0.21) -84.14%,
    rgba(0, 255, 255, 0) 105.06%
  );
}
.inactiveBtnMeta {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: transparent;
  color: #2b2b2b;
  background: linear-gradient(
    55.36deg,
    #6d3e66 -1.94%,
    rgba(0, 255, 255, 0) 115.94%
  );
  color: white;
}

.meta_Slider_box {
  border: 2px solid #ffffff12;
  background: linear-gradient(
    32.95deg,
    rgba(0, 255, 255, 0.21) -84.14%,
    rgba(0, 255, 255, 0) 105.06%
  );
  border-radius: 36px;
}
.meta_back {
  top: -10% !important;
  z-index: 999 !important;
  position: absolute !important;
}
.meta_list {
  font-family: ManropeRegular;
  font-weight: 300;
  font-size: 12px;
}
.meta_slider_p {
  font-family: InterLight;
  font-weight: 300;
  font-size: 11px;
  color: #a3a3a3;
}
.meta_slider_h2 {
  font-family: Questrial;
  font-weight: 400;
}
.arrow_meta_slider {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    32.95deg,
    rgba(0, 255, 255, 0.21) -84.14%,
    rgba(0, 255, 255, 0) 105.06%
  );
  border: 2px solid #ffffff12;
}


@media (min-width: 768px) and (max-width: 1024px) {
  .english-word {
    font-size: 80px;
    letter-spacing: 10px;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .english-word {
    font-size: 55px;
    letter-spacing: 0px;
  }
}
@media (max-width: 768px) {
  .meta_h2 {
    font-size: 20px;
  }
  .meta_box1 {
    margin-left: 10%;
  }
  .meta_box2 {
    margin-left: 2%;
    margin-right: 2%;
  }
  .meta_p_3 {
    font-size: 8px;
  }
  .StaffAugmentationProcessFlow {
    margin-left: 7.3% !important;
    margin-right: 3% !important;
}
  .staffAugmentationHowItWorkSection {
      padding-left: 3% !important;
      padding-right: 0% !important;
  }
}
@media (max-width: 568px) {
  .meta_h2 {
    font-size: 20px;
  }
  .MetaIMG3 {
    margin-top: 0%;
  }
  .MetaIMG {
    width: 100px !important;
  }
  .meta_box3 {
    background-image: url("./assets/images/services/Meta_Bg_4.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .meta_Slider_box {
    border-radius: 20px;
  }
  .meta_h1 {
    font-size: 22px;
  }
  .MetaIMG3_Blur {
    display: none;
  }
  .meta_p_3 {
    font-size: 11px;
  }
  .meta_small_slider {
    margin-left: 5%;
    margin-right: 5%;
  }
  .meta_main_bg {
    height: 500px;
  }
  .meta_box4 {
    padding-top: 70px;
    padding-bottom: 45px;
  }
}

.main_div_uiux {
  background-image: url("./assets/images/services/UIUX_Main.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f7fcff;
}
.main_div_uiux1 {
  background-image: url("./assets/images/services/UIUX_Main1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ebf3fe;
}
.uiux_box2 {
  padding-left: 16.5%;
  padding-right: 16.5%;
}
.uiux_box {
  margin-left: 33.5%;
}
.StaffAugmentationMain {
  background-color: #f4f7fe;
}
.augmentation_headings {
  font-size: 32px;
  font-family: ManropeRegular;
}

.augmentation_heading1 {
  font-family: ManropeRegular;
  font-size: 50px;
  font-weight: 400;
  line-height: 50px;
}

.staffAugmentationNumber {
  font-size: 100px;
  font-family: ManropeRegular;
  font-weight: 800;
  color: #ededed;
  margin-left: 0px !important;
  line-height: 78px !important;
}

.augmentation_para{
  font-size: 14px !important;
  font-family: ManropeRegular;
}
.staffAugmentationHowItWorks {
  background-color: #f4f7fe;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  margin-left: 0px;
}
.staffAugmentationNumber-container {
  width: 250px !important;
  display: flex;
  align-items: start;
  justify-content: start;
  margin-right: 15px;
  margin-left: 0px;
}
.guaranteeCardContent {
  padding: 0% 7% 0% 0%;
}

.staffAugmentationGuaranteeSection {
  padding-left: 16.7%;
}

.bgStaffHeader {
  background-color: #f4f7fe !important;
}
.augmentation_box {
  margin-left: 33.5%;
}
.guaranteecardText {
  font-size: 10px;
  font-family: ManropeRegular;
  margin-bottom: 0px;
}
.guaranteecardHeading {
  font-size: 18px;
  font-family: ManropeRegular;
}
.guaranteeNumberHeading {
  font-family: ManropeRegular;
  font-size: 18px;
  margin-bottom: 0px;
}
.staffAugmentationHowItWorkSection {
  padding-left: 11.5%;
  padding-right: 11.7%;
}
.AugmentationWorkTogether.gradient-div {
  background-image: linear-gradient(to bottom right, #fff5f5, #ffffff);
}
.staffguaranteeCard:hover {
  background-color: #f4f7fe;
  border-radius: 8px;
  cursor: pointer;
}
.staffguaranteeCard {
  border: 1px solid #F5F5F5;
  border-radius: 8px;
}
.staffAugmentationHeroSection {
  padding-bottom: 3%;
  background-color: #f4f7fe;
}
.augmentationHeroImage img {
  width: 80%;
  height: 64%;
}
.augmentationHeroImage {
  margin-top: auto;
}
.augmentationHeroBtn {
  font-size: 14px;
}
.uiux_h2 {
  font-family: "UrbanistReg";
  color: #2b2b2b;
  font-weight: 500;
}
.uiux_span {
  font-family: "UrbanistBold";
  color: #2d68d7;
  font-weight: 700;
}
.uiux_para {
  color: #2b2b2b;
  font-family: "Roboto-Light";
  font-size: 13px;
  font-weight: 300;
}
.uiux_btn {
  border-radius: 30px;
  border: 1px solid #2b2b2b;
  background-color: transparent;
  font-family: "ManropeBold";
  color: #2b2b2b;
  font-weight: 700;
  font-size: 14px;
}
.LaptopUIUX {
  width: 100%;
}
.uiux_btn:hover {
  border: 1px solid #2b2b2b;
  background-color: #2b2b2b;
  color: white;
}
.uiux_para1 {
  font-family: "ManropeBold";
  color: #2b2b2b;
  font-weight: 700;
  font-size: 14px;
}
.uiux_h2_1 {
  font-family: "UrbanistReg";
  font-weight: 400;
}
.uiux_box1 {
  margin-left: 16.5%;
  margin-right: 16.5%;
}
.uiux_para2 {
  font-family: "Roboto-Regular";
  font-weight: 400;
  box-shadow: 0px 4px 25px 0px #3c50e057;
  background: #254b8b;
  font-size: 12px;
}
.uiux_para3 {
  font-family: "Roboto-Regular";
  font-weight: 400;
  color: #4b4b4b;
  font-size: 12px;
}
.uiux_h4 {
  font-family: "Roboto-Medium";
  font-weight: 500;
  color: #2b2b2b;
}
.uiux_h4_2 {
  font-family: "UrbanistReg";
  font-weight: 400;
}
.uiux_para4 {
  font-family: "Roboto-Regular";
  font-weight: 400;
  color: #4b4b4b;
  font-size: 11.5px;
}
.uiux_para5 {
  font-family: "Roboto-Regular";
  font-weight: 400;
  color: #4b4b4b;
  font-size: 11.5px;
}
.uiux_para6 {
  font-family: "Roboto-Regular";
  font-weight: 400;
  color: #4b4b4b;
  font-size: 10px;
}
.ResearchUIUX {
  border-radius: 10px;
  background: #fcedee;
  box-shadow: 9px 14px 25.7px 0px rgba(0, 0, 0, 0.17);
}
.DesignUIUX {
  border-radius: 10px;
  background: #bed3ff;
  box-shadow: 9px 14px 25.7px 0px rgba(0, 0, 0, 0.17);
}
.ImplementationUIUX {
  border-radius: 10px;
  background: #defeea;
  box-shadow: 9px 14px 25.7px 0px rgba(0, 0, 0, 0.17);
}
.TestingUIUX {
  border-radius: 10px;
  background: #ffeea6;
  box-shadow: 9px 14px 25.7px 0px rgba(0, 0, 0, 0.17);
}
.uiux_h5 {
  color: #2b2b2b;
  font-family: "UrbanistMedium";
  font-weight: 500;
  font-size: 15px;
}
.uiux_box3 {
  padding-left: 0%;
  padding-right: 16.5%;
}
.uiux_box4 {
  padding-left: 32.5%;
}
.uiux_h2_3 {
  font-family: UrbanistBold;
  font-weight: 700;
  color: #254b8b;
}
.uiux_para7 {
  font-family: "Roboto-Regular";
  font-weight: 400;
  color: #000000;
  font-size: 12px;
}
.WiserbeeUIUX {
  margin-left: -10%;
}
.uiux_box5 {
  background-color: #ebf3fe;
}
.uiux_box6 {
  background-color: #ebf3fe;
  margin-top: 6% !important;
}
.main_box_UIUX {
  display: flex;
}
.main_box_UIUX2 {
  display: none;
}
@media (max-width: 951px) {
  .main_box_UIUX {
    display: none;
  }
  .main_box_UIUX2 {
    display: flex;
  }
}

@media (max-width: 1024px) {
  .augmentation_para{
    font-size: 12px !important;
  }
  .augmentation_headings{
    font-size: 26px !important;
  }
  .staffAugmentationHowItWorkSection {
    padding-left: 12.7%;
    padding-right: 11.7%;
  }
  
  .mainCardEnhanceTeam{
    margin-right: 3%;
  }
  .has-float-label > span {
    font-size: 14px;
}
.has-float-label{
  height: 23vh !important;
}
.guaranteecardText {
  font-size: 9px;
  margin-bottom: 0px;
}
  .uiux_box3 {
    padding-left: 4%;
    padding-right: 4%;
  }
  .uiux_h5 {
    font-size: 13px;
  }
  .uiux_para6 {
    font-size: 9px;
  }
  .augmentation_heading1 {
    font-family: ManropeRegular;
    font-size: 30px;
    font-weight: 400;
    line-height: 34px;
  }
  
}
@media (max-width: 768px) {
  .uiux_box {
    margin-left: 17%;
    margin-top: 10%;
  }
  .uiux_box3 {
    padding-left: 4%;
    padding-right: 4%;
  }
  .uiux_box2 {
    padding-left: 8.5%;
    padding-right: 8.5%;
  }
  .uiux_box4 {
    padding-left: 8.5%;
  }
  .mainCardEnhanceTeam{
    margin-left: 7%;
    margin-right: 3%;
  }
  
}
@media (max-width: 576px) {
  .uiux_box {
    margin-left: 5%;
    margin-top: 10%;
  }
  .uiux_box1 {
    margin-left: 2%;
    margin-right: 2%;
  }
  .uiux_box2 {
    padding-left: 8.5%;
    padding-right: 8.5%;
  }
  .uiux_para4 {
    font-size: 10px;
  }
  .uiux_h4 {
    font-size: 14px;
  }
  .uiux_h2_1 {
    font-size: 20px;
  }
  .uiux_h4_2 {
    font-size: 20px;
  }
  .uiux_para5 {
    font-size: 10.5px;
  }
  .uiux_box3 {
    padding-left: 4%;
    padding-right: 4%;
  }
  .uiux_box4 {
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 5%;
  }
  .WiserbeeUIUX {
    margin-left: 0% !important;
  }
}
@media (max-width: 992px) {
  .augmentation_box {
    margin-left: 16.5% ;
    margin-top: auto;
  }
  .staffAugmentationGuaranteeSection {
    padding-left: 7.7%;
    width: 100%;
  }
}
