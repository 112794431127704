@font-face {
  font-family: "UrbanistReg";
  src: url("../src/assets/fonts/Urbanist-Regular.ttf");
}
@font-face {
  font-family: "UrbanistBold";
  src: url("../src/assets/fonts/Urbanist-Bold.ttf");
}
@font-face {
  font-family: "UrbanistSemiBold";
  src: url("../src/assets/fonts/Urbanist-SemiBold.ttf");
}
@font-face {
  font-family: "ManropeBold";
  src: url("./assets/fonts/Manrope-Bold.ttf");
}
@font-face {
  font-family: "ManropeExtraBold";
  src: url("./assets/fonts/Manrope-ExtraBold.ttf");
}
@font-face {
  font-family: "ManropeRegular";
  src: url("./assets/fonts/Manrope-Regular.ttf");
}
@font-face {
  font-family: "ManropeSemiBold";
  src: url("./assets/fonts/Manrope-SemiBold.ttf");
}
@font-face {
  font-family: "ManropeExtraLight";
  src: url("./assets/fonts/Manrope-ExtraLight.ttf");
}
@font-face {
  font-family: "ManropeLight";
  src: url("./assets/fonts/Manrope-Light.ttf");
}
@font-face {
  font-family: "ManropeMedium";
  src: url("./assets/fonts/Manrope-Medium.ttf");
}
@font-face {
  font-family: "MerriweatherBold";
  src: url("./assets/fonts/Merriweather-Bold.ttf");
}
@font-face {
  font-family: "MerriweatherLight";
  src: url("./assets/fonts/Merriweather-Light.ttf");
}
@font-face {
  font-family: "MerriweatherRegular";
  src: url("./assets/fonts/Merriweather-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Black";
  src: url("./assets/fonts/Roboto-Black.ttf");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("./assets/fonts/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto-Light";
  src: url("./assets/fonts/Roboto-Light.ttf");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("./assets/fonts/Roboto-Medium.ttf");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto-Thin";
  src: url("./assets/fonts/Roboto-Thin.ttf");
}

:root {
  --navbar-link-color: white;
  --navbar-active-color: #0a76db;
  --text-light-gray: #8c8c8c;
  --text-light-black: #2b2b2b;
  --home-text-color: #d9d9d9;
  --home-btn-color: #303030;
  --footer-background-color: #000e26;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "UrbanistReg";
}
p {
  font-family: "ManropeRegular";
}
body {
  background-color: #f7fcff;
  overflow-x: hidden;
}
.nav-link {
  cursor: pointer;
}
.gapSty-8 {
  gap: 8%;
}
.mb-1-5 {
  margin-bottom: 0.15rem !important;
}
.navbar-collapse {
  flex-grow: unset;
}
.ml-5 {
  margin-left: 35%;
}
.mr-1 {
  margin-right: 10px;
}
.ml-1 {
  margin-left: 10px;
}
.mt-10 {
  margin-top: 50px;
}
.pl-2 {
  padding-left: 20px;
}
.grayTxt {
  color: rgba(150, 150, 150, 1);
}
.pl-5 {
  padding-left: 50px;
}

::-webkit-scrollbar {
  width: 10px;
}
.pl-0 {
  padding-left: 0px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.serviesTech {
  padding: 70px 0;
}
.pragraphSty {
  font-size: 15px;
  font-weight: 500;
  font-family: "ManropeRegular";
}
.pragraphStyMission {
  font-size: 12px;
  font-weight: 500;
  height: 55px;

  font-family: "ManropeRegular";
}
.pragraphSty.contactFontSize {
  font-size: 12px;
  font-weight: 700;
  font-family: "ManropeSemiBold";
}
.headingSty {
  font-size: 27px !important;
  font-weight: 300;
  font-family: "UrbanistReg";
}
.headingSty4 {
  font-size: 27px;
  font-weight: 300;
  font-family: "UrbanistReg";
}
.mapImages {
  width: 100%;
  height: 100%;
}
.heading2Sty {
  font-size: 25px;
  font-family: "UrbanistReg";
  font-weight: 500;
}
.heading2Sty.homePage {
  font-size: 20px !important;
  font-family: "UrbanistReg";
  font-weight: 500;
}
.pragraph2Sty {
  font-size: 18px;
  font-weight: 400;
}
.borderline {
  border-left: 2px solid;
  border-color: rgba(31, 49, 65, 0.09);
}
.hrColor {
  border-top: 2px solid;
  border-color: rgba(31, 49, 65, 0.09);
}
.harnessing {
  padding: 20px;
  background: rgba(31, 49, 65, 0.06);
  border-radius: 30px;
}

.mainPage {
  background-color: #f7fcff;
  padding-left: 0px;
  padding-right: 0px;
}
.harnessing,
.slider_background {
  margin: 0 13px;
}

.swatiIconbg {
  background-image: url("./assets/images/SwatitechlogoBG.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.shouldCase {
  background-image: url("./assets/images/BGService.png");
  background-size: cover;
  padding: 30px;
  margin: 65px 0;
}
.touchusBtn {
  padding: 16px, 30px, 16px, 30px;
  width: 250px;
  height: 50px;
  background-color: #fff;
  border-radius: 30px;
  font-weight: bolder;
}
.servicesBox {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0.01),
    0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01),
    0px 20px 13px 0px rgba(0, 0, 0, 0.01),
    0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.02),
    0px 64.815px 46.852px 0px rgba(0, 0, 0, 0.02),
    0px 100px 80px 0px rgba(0, 0, 0, 0.03);
}
.servicesIcons {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: rgba(64, 175, 253, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  box-shadow: 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0.01),
    0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01),
    0px 20px 13px 0px rgba(0, 0, 0, 0.01),
    0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.02),
    0px 64.815px 46.852px 0px rgba(0, 0, 0, 0.02),
    0px 100px 80px 0px rgba(0, 0, 0, 0.03);
}
.servicesBox h6 {
  font-size: 16px;
  font-weight: 600;
  font-family: UrbanistReg;
}
.servicesBox p {
  font-size: 14px;
  font-family: "ManropeRegular";
  font-weight: 400;
  padding: 10px 0px;
}
.ourClientsDesign {
  background-color: #f7fcff;
  padding: 50px 0px;
}
.md-position-relative {
  position: relative;
}
.md-position-absolute {
  position: absolute;
}
.clientName {
  padding: 50px 0;
}

.brand {
  width: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.circle_boxShadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  background-color: #fff;
}
.circle_boxLine {
  border: 0.94px solid #e3e8eb;
}
.circle_boxLine2 {
  border: 0.94px solid #2a4c606e;
}
.bluePls {
  color: rgba(64, 175, 253, 1);
  margin-left: -7px;
}
.reveniueDesign div p {
  padding: 0px 12px;
  margin: 0px !important;
  font-size: 12px;
}
.reveniueDesign {
  border-radius: 100%;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.intenationlClient {
  width: 100%;
  height: auto;
}
.awssld__content {
  background-color: transparent;
}
.globalizedSty {
  font-size: 12px;
  font-weight: 400;
  font-family: "ManropeRegular";
  border-left: 2.5px solid #0a76db;
  padding-left: 8px;
}
.globalImg {
  width: 100%;
}
.globalImg img {
  width: 100%;
  height: 100%;
}
.divderLine {
  border-bottom: 5px solid;
  border-color: rgba(31, 49, 65, 0.09);
}
.borderline5px {
  border-left: 5px solid;
  border-color: rgba(31, 49, 65, 0.09);
}
.Certificate {
  border: 2px solid #e8e8e8;
  border-radius: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px 0px;
  background-color: rgba(247, 252, 255, 1);
}

.CertificateBox img {
  width: 65%;
}
.QRS {
  font-size: 26px;
  font-weight: 700;
  font-family: ManropeBold;
  padding: 5px;
  text-align: center;
}
.certificatpara {
  background-color: rgba(0, 73, 135, 1);
  font-size: 11px;
  color: #fff;
  text-align: center;
}
.apprication {
  width: 80%;
}
.apprication img {
  width: 100%;
  height: auto;
}
.teamProfile {
  margin-bottom: 20px;
}

.OurTeam {
  display: flex;
  justify-content: center;
  height: 220px;
  width: 100%;
  position: relative;
  border-radius: 13px;
  border: 6px solid rgba(238, 238, 238, 1);
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.OurTeam img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.teamDetails {
  position: absolute;
  background-color: #fff;
  width: 90%;
  border-radius: 10px;
  bottom: 20px;
  left: 5%;
  padding: 5px;
}
.teamDetails h6 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 14px;
}
.teamDetails p {
  text-align: center;
  font-size: 10px;
  margin-bottom: 0px;
  font-weight: 600;
  color: rgba(6, 85, 140, 1);
}
.modal-body .container-fluid {
  margin-top: 10px;
  border-radius: 13px;
  border: 1px solid #343434 !important;
  background: rgba(0, 3, 9, 0.52);
  backdrop-filter: blur(15px);
  padding: 15px 8px 0px 8px ;
}
.px-2o5{
  padding: 0px 7px;
}
.mb-2o5{
  margin-bottom: 15px;
}
.modal_navbar_box:hover {
  outline: 1px solid #0a76db;
  background-color: #111721 !important;
}

.modal_navbar_box {
  padding: 15px !important;
  border: 1px solid;
  border-color: rgba(13, 81, 146, 0.35);
  cursor: pointer;
  background: #03080e !important;
  width: 100%;
  height: 120px;
}

@media screen and (min-width: 994px) and (max-width: 1200px) {
  .modal_navbar_box {
    height: 140px !important;
  }
}
.careersHero {
  border-radius: 10px;
}
.jobsCard {
  margin-bottom: 22px !important;
  width: 100%;
  border-radius: 9px !important;
  border: 1.5px solid #b6b6b6 !important;
  background-color: #f7fcff;
}

.jobsCard:hover {
  box-shadow: 0px 14.8px 68.76px 0px #00000012 !important;
  border: 1.5px solid #f7fcff !important;
  /* width: 100%; */
  border-radius: 9px !important;
  background-color: #fff;
}
.errorText{
  font-size: 12px !important;
}

.jobsCard h4 {
  font-weight: 400;
  font-size: 15px;
  color: rgba(43, 43, 43, 1);
}
.styleApplyNowBtn {
  color: white;
  width: 80px;
  background-color: rgba(10, 118, 219, 1);
  border: 1px solid rgba(10, 118, 219, 1);
  border-radius: 15.94px;
  font-size: 10px;
  font-family: "ManropeSemiBold";
  font-weight: 500 !important;
  padding: 5px 5px;
}
.careerJobTitle {
  font-weight: 600;
  font-size: 14px;
  text-wrap: nowrap;
  font-family: "ManropeSemiBold";
}
.styleViewDetailBtn {
  color: black;
  width: 80px;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 15.94px;
  font-size: 10px;
  font-family: "ManropeSemiBold";
  font-weight: 500 !important;
  padding: 5px 5px;
}
.navbar_height{
  height: 40px;
}
.googleMapLocation {
  width: 100%;
  height: 550px;
  position: relative;
}
.googleMapLocation .mainMap {
  width: 100%;
  height: 100%;
}
.pinImageUSA {
  position: absolute;
  top: 36%;
  left: 25%;
  z-index: 1;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.pinImageUAE {
  position: absolute;
  top: 49%;
  left: 61%;
  z-index: 1;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.pinImageKHI {
  position: absolute;
  top: 47%;
  left: 65%;
  z-index: 1;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.pinImageLHE {
  position: absolute;
  top: 40%;
  left: 65%;
  z-index: 1;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.xproHotspotTooltipText {
  position: absolute;
  background-color: rgba(247, 252, 255, 1);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #000;
  padding: 10px;
  border-radius: 15.94px;
  display: none;
  z-index: 2;
}
.pinImageUSA:hover + .xproHotspotTooltipText {
  display: block;
  top: 8%;
  left: 15.5%;
}
.pinImageUAE:hover + .xproHotspotTooltipText {
  display: block;
  top: 21%;
  left: 53%;
}
.pinImageLHE:hover + .xproHotspotTooltipText {
  display: block;
  top: 12%;
  left: 58%;
}
.pinImageKHI:hover + .xproHotspotTooltipText {
  display: block;
  top: 19%;
  left: 56.5%;
}
.xproHotspotTooltipText p {
  text-align: start;
  margin-bottom: 5px;
}

.tooltip-bottom::before {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  bottom: 5px;
  border: 15px solid transparent;
  border-bottom: 0;
  border-top: 15px solid rgba(247, 252, 255, 1);
  transform: translate(-50%, calc(100% + 5px));
}

.carrierDetails {
  background-image: url("./assets/images/BG_CareerDetail.png");
  background-position: contain;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.smDisplayNone {
  display: none;
}
.CopyRightSm {
  display: none;
  padding: 10px 0px;
}
.CopyRightLg {
  display: block;
}
.CopyRightSm p {
  font-size: 10px;
}
.smDisplay {
  display: none;
}
.attendifyPage li a,
.attendifyPage li .nav-link,
.attendifyPage li .nav-link .lucide {
  color: #000 !important;
}
.missionVission {
  border-radius: 19.383px;
  border: 4px solid rgba(232, 232, 232, 0.6);
  background-color: #f7fcff;
  padding: 20px;
}
.missionVission:hover {
  box-shadow: 0px 18px 59.3px 0px rgba(0, 0, 0, 0.1);
}
.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}

@media only Screen and (min-width: 992px) and (max-width: 1024px) {
  .careerJobTitle {
    font-weight: 600;
    font-size: 13px;
    text-wrap: nowrap;
    font-family: "ManropeSemiBold";
  }
}
@media (max-width: 575.98px) {
  .careerJobTitle {
    text-wrap: wrap;
    margin-top: 5px !important;
  }
  .md-position-relative {
    position: unset;
  }
  .workseeLogo {
    display: none;
    margin: 50px;
  }
  .missionVission {
    border: none !important;
  }
  .missionVission:hover {
    box-shadow: unset;
  }
  .jobsCard {
    width: 96%;
  }
  .missionVission.ourMission {
    border-radius: 19.383px;
  }
  .careersHero {
    border-radius: 0px !important;
  }
}
@media (max-width: 767px) {
  .careerJobTitle.careerDetail {
    text-align: center !important;
  }
}
@media (max-width: 992px) {
  .careerJobTitle.careerDetail {
    text-wrap: wrap;
  }
}
@media (max-width: 375px) {
  .reveniueDesign div p {
    font-size: 10px !important;
  }
  .reveniueDesign {
    width: 120px;
    height: 120px;
  }
  .headingSty4.HomeHeading {
    font-size: 15px !important;
  }
  .headingSty4.ourProduct {
    font-size: 18px !important;
  }
}
@media (min-width: 300px) and (max-width: 767.98px) {
  .reveniueDesign div p {
    font-size: 10px !important;
  }
  .md-mt-4 {
    margin-top: 40px;
  }
  .borderline5px {
    border: none;
  }
  .Certificate {
    margin-bottom: 20px;
  }
  .globalImg {
    margin-top: 30px;
  }

  .mt-10 {
    margin-top: 0px;
  }
  .ourClientsDesign {
    padding: 20px;
  }
  .gapSty-8 {
    display: flex;
    justify-content: space-between !important;
  }
  .smScreen {
    padding: 0px !important;
    margin: 0px !important;
  }
  .smScrenRes {
    margin: 20px 0px !important;
    padding: 0px !important;
  }
  .headingSty,
  .headingSty4 {
    font-size: 24px;
    text-align: center;
  }
  .headingSty4.ourProduct {
    font-size: 22px;
    text-align: center;
    margin: 0px 1px;
  }
  .certi_main_div {
    background-image: none;
    background-color: #f7fcff;
  }
  .QRS {
    font-size: 20px;
  }
  .certificatpara {
    margin-bottom: 0.5rem;
  }
  .smTxtCenter {
    text-align: center;
  }
  .smAlignmentCenter {
    display: flex;
    justify-content: center;
  }
  .smAlignmentStart {
    display: flex;
    justify-content: flex-start !important;
  }
  .apprication {
    width: 100%;
  }
  .apprication p svg {
    width: 100%;
  }
  .pragraphSty {
    font-size: 11px;
    justify-content: center !important;
    margin: 13px 0px;
  }
  .pragraphCareer.careerHeroSectionTextMobile {
    font-size: 11px;
    justify-content: center !important;
    margin: 0px !important;
  }
  .pragraphStyMission {
    font-size: 11px;
    height: unset !important;
  }
  .shouldCase {
    padding: 0px !important;
    margin: 0px !important;
  }
  .OurTeam {
    height: 170px;
  }
  .teamDetails {
    bottom: 5px;
  }
  .teamDetails h6 {
    font-size: 12px;
  }
  .teamDetails p {
    font-size: 8px;
  }
  .smDisplayNone {
    display: block;
  }
  .CopyRightLg {
    display: none;
  }
  .CopyRightSm {
    display: block;
  }
  .serviesDetails {
    text-align: center;
  }
  .smDisplay {
    display: block;
  }
  .mapImages {
    width: 90%;
    display: flex;
    padding-bottom: 15px;
  }
  .serviesTech {
    padding: 20px 10px;
  }
  .borderline {
    border: none;
    margin-top: 15px;
  }
  .harnessing {
    padding: 5px !important;
    margin: 15px 0px;
  }
  .careersHero {
    padding: 10px 0px !important;
  }
  .heading2Sty.careerHeroHeading {
    font-size: 16px !important;
    margin-top: 3px;
    margin-bottom: 10px;
  }
  .hrColor {
    margin: 20px 0px !important;
  }
  .smShouldView {
    margin: 20px 0px !important;
  }
  .servicesBox {
    margin-top: 20px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .md-position-absolute {
    position: unset;
    padding-bottom: 20px;
  }
  .smShouldView p {
    text-align: center;
  }
  .smMarginVivew.HomeSection {
    display: flex;
    flex-direction: column-reverse !important;
    margin: 0px !important;
    padding: 0px !important;
    text-align: center;
  }
  .smMarginVivew {
    display: flex;
    margin: 0px !important;
    text-align: center;
  }
  .smTextView .headingSty {
    text-align: left;
  }
  .sm-py-20 {
    padding: 20px 0px !important;
  }
  .smTextStart {
    text-align: start;
  }
  .clientName {
    padding: 0px;
  }
  .modal_navbar_box {
    width: 100%;
  }
  .text_align_sm {
    text-align: center !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .md-position-relative {
    position: unset;
  }

  .mt-10 {
    margin-top: 0px;
  }
  .OurTeam {
    width: 75%;
  }
  .serviesTech {
    padding: 30px 10px !important;
  }
}

@media (min-width: 1200px) {
  .brandlog {
    gap: 5px;
  }
}
@media (min-width: 1450px) {
  .fontSize15{
    font-size: 15px !important;
  }
  .fontsize12{
    font-size: 12px;
  }

}
@media (min-width: 1450px) and (max-width: 1770px) {
  .globalImg {
    width: 100%;
    margin-left: 15%;
    height: 50vh;
  }
}
@media (min-width: 1771px) {
  .globalImg {
    width: 100%;
    margin-left: 15%;
    height: 60vh;
  }
}
@media (min-width: 1650px) {
  .navbar-nav.first .nav-link {
    font-size: 16px;
  }
  .home_h3 {
    font-size: 25px;
  }
  .home_paragrpgh_1 {
    font-size: 16px;
  }
  .headingSty4 {
    font-size: 45px;
  }
  .pragraphSty {
    font-size: 16px;
  }
  .pragraphStyMission {
    font-size: 16px;
    height: 75px;
  }
  .servicesBox p {
    font-size: 16px;
  }
  .servicesBox h6 {
    font-size: 20px;
  }
  .footer_para {
    font-size: 14px;
  }
  .footer_para_1 {
    font-size: 16px;
    margin: 20px 0px;
  }
  .footer_h5 {
    font-size: 25px;
  }
  .modal_navbar_p1 {
    font-size: 18px;
  }
  .legal1_main_div {
    background-size: cover;
  }
}





/* Aziz CSS */

.careere_main_div {
  background-image: url("./assets/images/BGContact.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(247, 252, 255) !important;
}
.carrierBg {
  background-image: url("./assets/images/BGCareerSwati.png");
  background-position: right;
  background-size: 18% 45%;
  background-repeat: no-repeat;
  background-position: right 0px bottom 8px;
  background-color: rgb(247, 252, 255);
  height: 50%;
  width: 100%;
}
.lastUpdate.legal_h5 {
  font-weight: 700;
}
.deleteMyDataWrapper {
  height: 700px;
  width: 100%;
  position: relative;
}
.blueSection {
  background-image: url("./assets/images/Frame.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right 1px top 48px;
  height: 50%;
  width: 100%;
  background-color: #e7f2ff;
}
.PersonalDataInDeleteMyData {
  margin: 3% 0px;
}
.careerDetailLocation {
  border-bottom: 3px solid #0a76db !important;
  font-size: 12px;
}
.sliderOtherPositions {
  background-image: url("./assets/images/BGCareerDetail_2.png");
  width: 100%;
  background-size: 16% 140% !important;
  background-repeat: no-repeat;
}
.aboutTheJobCareerDetail {
  background-image: url("./assets/images/BGCareerDetail_1.png");
  background-position-x: right;
  background-position-y: 29%;
  width: 100%;
  background-size: 16% 90% !important;
  background-repeat: no-repeat;
}
.careerPageJobscard.active {
  background-color: #eef6ff;
  border-radius: 6px;
}

.loadingMain {
  background-image: url("../src/assets/images/loadingBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: contain;
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.progress-container {
  width: 190px;
  background-color: #d1d1d1;
  border-radius: 4px;
  margin-top: 1.7%;
  overflow: hidden;
}

.progress-bar {
  height: 4px;
  background-color: #244885;
  border-radius: 4px;
  transition: width 0.01s linear;
  padding: 0;
}

.careerPageJobscard {
  background-color: transparent;
  border: none;
  width: 8%;
  font-size: 19px;
}
.careerHeroTextSection {
  margin-left: 35%;
}
.CareerPaginationRightBtn {
  background-color: #0a76db !important;
  border: none;
  color: white;
  padding: 7px;
  border-radius: 6px;
}
.CareerPaginationLeftBtn {
  background-color: #0a76db !important;
  border: none;
  color: white;
  padding: 7px;
  border-radius: 6px;
}
.heading2Sty.careerHeroHeading {
  font-size: 25px;
  font-family: "UrbanistReg";
  font-weight: 500;
}
.CareerHeroMain {
  background-color: rgb(247, 252, 255);
  width: 100%;
}
@media (max-width: 425px) {
  .pragraphSty.career {
    font-size: 10px;
    justify-content: center !important;
    margin: 0px 13px;
    text-align: center !important;
  }
  .heading2Sty.careerHeroHeading {
    font-size: 16px !important;
    margin-top: 3px;
    margin-bottom: 6px !important;
  }
}

@media (max-width: 525px) {
  .carrierBg {
    background-image: none;
  }
  .careerDetailPositions {
    font-size: 12px;
  }
  .careerDetailotherPositions {
    height: 40vh !important;
  }
  .blueSection {
    background-image: none !important;
  }
  .whiteSection {
    background-image: none !important;
  }
  .text_underlined_sm {
    text-decoration: underline;
  }
  .map_width_sm {
    width: 50vw;
  }
  .lastUpdate.legal_h5 {
    font-weight: 600 !important;
    font-size: 18.5px !important;
  }
}
@media (max-width: 526px) {
  .CareerHeroMain {
    background-color: rgb(247, 252, 255);
    margin: 0px 0px !important;
  }
  .CareerJobIconsbackground {
    background-color: #eef6ff;
    width: 42px !important;
    border-radius: 7px;
  }
}
.careerTechnTime {
  font-size: 10px;
  font-weight: 600;
  background-color: #eef6ff;
  border-radius: 7px;
  font-family: "ManropeRegular";
  padding: 6px;
  margin: 0;
}
.CareerJobIconsbackground {
  background-color: #eef6ff;
  width: 42px;
  border-radius: 7px;
}
.whiteSection {
  background-image: url("./assets/images/frame2.png");
  background-size: 30% 180%;
  background-repeat: no-repeat;
  background-position: left 0px bottom -250px;
  height: 50%;
  width: 100%;
  background-color: #f7fcff;
}
.blueSection .divider {
  height: 2px;
  width: 20px;
  background-color: #40affd;
}
.blueSection .heading h4 {
  font-size: 14px;
}
.deleteMydataForm {
  width: 100%;
  position: absolute;
  top: 20%;
  height: 500px;
}
.deleteMyDataForm textarea {
  font-size: 12px !important;
}

.deleteMyDataForm input,
.deleteMyDataForm input::placeholder {
  font-family: "ManropeRegular" !important;
  font-size: 12px !important;
}

.deleteMyDataForm {
  font-family: "ManropeRegular" !important;
  position: relative;
  background-color: #fdfdfd;
  border-radius: 10px;
  padding: 20px 20px;
  box-shadow: 0px 14.800000190734863px 48.75777816772461px 0px #0000001a;
  z-index: 12;
}
.dropdownSelectApp.show {
  border: 2px solid #0a76db !important;
  color: var(--bs-body-color);
}
.cursor-pointer {
  cursor: pointer;
}
.dropdownSelectApp {
  width: 100%;
  padding: 9px 10px;
  background-color: #f7f7f7 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #ebebeb;
  border-radius: 9px;
}
.colorSelectedApp {
  color: #757575;
  font-size: 12px !important;
}
.colorDefaultText {
  color: black;
  font-size: 12px !important;
}
.form-label.DeleteMyData {
  font-family: "UrbanistBold" !important;
  font-weight: 600 !important;
  color: #2b2b2b;
}
.listSelectApp:hover {
  background-color: #0a76db;
  border-radius: 5px;
  color: white;
}
.listSelectApp {
  font-size: 12px !important;
}
.dropdownMenuSelectApp {
  width: 100%;
  padding: 20px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dropdown-toggle::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%238c8c8c%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5px;
  vertical-align: middle;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
}
.formDelete input:focus {
  border: 2px solid #0a76db !important;
  outline: none;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-clip: padding-box;
  border-radius: var(--bs-border-radius);
  background-color: #f7f7f7 !important;
  border-radius: 10px;
}
.formDelete .btnDelete {
  background-color: #0a76db;
  padding: 10px 15px;
  border-radius: 30px;
  border: none;
  font-size: 14px;
}

.cookiesMain {
  background-image: url("./assets/images/cookiesBanner.png");
  background-position: right 29%;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  z-index: 10;
  bottom: 0;
}
.cookiesText .cookies {
  font-family: Roboto-Bold;
  font-size: 20px;
  margin-bottom: 0;
}
.cookiesText .cookiesExplanation {
  font-family: Roboto-Regular;
  font-size: 11px;
  margin-bottom: 0;
}

.cookiesButtons .acceptCookies button {
  border: none;
  background-color: transparent;
}
.cookiesButtons .rejectCookies button {
  border: none;
  background-color: transparent;
}
.acceptCookies {
  width: 38%;
  background-color: #348cc9;
  border: 1.4px solid #40affd;
  border-radius: 7px;
  font-family: Roboto-Regular;
  color: white;
  font-size: 13px;
  text-wrap: nowrap;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rejectCookies {
  width: 38%;
  text-wrap: nowrap;
  background-color: transparent;
  height: 35px !important;
  font-family: Roboto-Regular;
  border: 1.4px solid #2b2b2b;
  border-radius: 7px;
  font-size: 13px;
  color: #2b2b2b;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cookiesCloseButton {
  padding: 5px;
  border: none;
  background: none;
}
.cookiesCloseButton .closeIcon {
  color: #2b2b2b;
}
.productMobile {
  color: white !important;
}
.servicesMobile {
  color: white !important;
}
.productMobileMenu li:hover {
  color: var(--navbar-active-color);
}
.servicesMobileMenu li:hover {
  color: var(--navbar-active-color);
}
.productMobileMenu li {
  list-style: disc;
  font-size: 10px;
  margin-top: 5px;
  font-family: UrbanistReg;
}
.servicesMobileMenu li {
  list-style: disc;
  font-size: 10px;
  margin-top: 5px;
  font-family: UrbanistReg;
}
.IsoCertificates {
  border: 2px solid #e8e8e8;
}
.DeleteDataDropdown {
  border-radius: 12px !important;
}
.aboutGlobeMainDiv {
  margin: auto !important;
}
.navBarPositionSticky{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  color: #2b2b2b !important;
  background-color: #ffffff !important;
  z-index: 1000; 
}

@media (max-width: 1024px) {
  .acceptCookies {
    font-size: 10px;
  }
  .rejectCookies {
    font-size: 10px;
  }
}
@media (min-width: 1440px) {
  .cookiesMain {
    background-image: url("./assets/images/cookiesBanner.png");
    background-position: right 29%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@media (max-width: 768px) {
  .careerHeroTextSection {
    margin-left: 0%;
  }
  .lastUpdate.legal_h5 {
    font-weight: 600;
    font-size: 20px;
  }
  .PersonalDataInDeleteMyData {
    margin: 5% 0px !important;
  }
}

@media (max-width: 767px) {
  .cookiesMain {
    background-image: url("./assets/images/BGCookiesResponsive.png");
    background-position: right;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .acceptCookies {
    width: 50%;
  }
  .rejectCookies {
    background-color: white;
    width: 50%;
  }
  .nav_border {
    border-bottom: none !important;
  }
  .form-label.DeleteMyData {
    font-family: "UrbanistReg" !important;
    font-weight: 600 !important;
    color: #2b2b2b;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .missionVission:hover {
    box-shadow: none !important;
  }
  .apprication img {
    width: 160px !important;
    height: auto;
  }
  .servicesBox {
    height: 235px;
    width: 170px !important;
  }
  .servicesIcons {
    width: 45px !important;
    height: 45px !important;
  }
  .servicesBox p {
    font-size: 14px !important;
  }
  .servicesBox h6 {
    font-size: 15px !important;
  }
  .headingSty.aboutMeettheMinds {
    font-size: 26px !important;
  }
  .reveniueDesign {
    width: 130px !important;
    height: 130px !important;
  }
  .reveniueDesign div p {
    font-size: 10px !important;
  }
  .home_paragrpgh_1 {
    font-size: 13px !important;
  }
  .home_btn:hover {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    background-color: var(--navbar-link-color);
    font-family: ManropeBold;
    color: var(--home-btn-color);
    border: 1px solid transparent;
  }
  .home_btn {
    padding: 10px;
    padding-left: 70px;
    padding-right: 70px;
    border-radius: 30px;
    background-color: var(--navbar-link-color);
    font-family: ManropeBold;
    color: var(--home-btn-color);
    border: 1px solid transparent;
  }
  .home_btn:hover {
    padding: 10px;
    padding-left: 70px;
    padding-right: 70px;
    border-radius: 30px;
    background-color: var(--navbar-link-color) !important;
    font-family: ManropeBold;
    color: var(--home-btn-color) !important;
    border: 1px solid transparent;
  }
  .career_btn.careerDetails {
    padding: 8px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    border-radius: 30px;
    background-color: #0a76db;
    font-family: ManropeBold;
    color: white;
    border: 2px solid #0a76db;
    box-shadow: 0px 19px 26px -9px #0000002b;
  }
  .styleApplyNowBtn {
    color: white;
    width: 70px !important;
    background-color: rgba(10, 118, 219, 1);
    border: 1px solid rgba(10, 118, 219, 1);
    border-radius: 15.94px;
    font-size: 9px !important;
    font-weight: 500 !important;
    padding: 5px 3px;
  }
  .styleViewDetailBtn {
    color: black;
    width: 70px !important;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 15.94px;
    font-size: 9px !important;
    font-weight: 500 !important;
    padding: 5px 3px;
  }
  .careers_h3_1.careerSubHeadingFontSize {
    font-size: 19px !important;
    font-family: "ManropeSemiBold" !important;
  }
  .careers_h3.careerDetailHeading {
    color: #0a76db;
    font-family: "UrbanistBold";
    font-size: 23px !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .brand {
    width: 21% !important;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
}

/* Aziz CSS */



/* Usman CSS */
.toggle-menu {
  display: none;
}

.menu-items {
  padding: 0;
  margin: 0;
  background: transparent;
  height: 100vh;
  width: 100%;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  background-image: url("./assets/images/menu.png");
  background-size: cover;
  height: 100vh;
  background-image: url("./assets/images/menu.png");
  background-size: cover;
  height: 100vh;
  z-index: 9999;
}

.menu-items.open {
  transform: translateX(0);
}

#nav ul {
  list-style: none;
  font-family: verdana, sans-serif;
  font-weight: bold;
  text-align: left;
  width: 100%;
  padding: 0;
}

#nav .listiFirst {
  background-color: transparent;
  border-bottom: 1px solid #ffffff12;
  display: block;
  text-decoration: none;
  padding-bottom: 4px;
  padding-top: 20px;
}

.navlinkfirst {
  font-size: 18px;
  color: var(--navbar-link-color);
  font-family: "Roboto-Medium";
  margin-left: 10px;
  font-weight: 500;
}

#nav a:hover {
  background-color: transparent;
  color: #0176db;
}

#nav a:active {
  background-color: transparent;
  color: #0176db;
}

#nav .dropdown .orderlisti {
  background-color: transparent;
  border-bottom: 1px solid #ffffff12;
  display: block;
  text-decoration: none;
  padding-bottom: 4px;
  padding-top: 20px;

  font-size: 18px;
  color: var(--navbar-link-color);
  font-family: "Roboto-Medium";
  font-weight: 500;
  margin-left: 10px;
}

#nav .dropdown .listi {
  background-color: transparent;
  margin-left: 30px;
  margin-top: 5%;
  font-size: 13px;
  color: var(--navbar-link-color);
  font-family: "UrbanistReg";
}

#nav .dropdown:hover {
  background-color: transparent;
  color: #0176db;
}
#nav .dropdown:active {
  background-color: transparent;
  color: #0176db;
}
#nav .dropdown .listi:hover {
  background-color: transparent;
  color: #0176db;
}
#nav .dropdown .listi:active {
  background-color: transparent;
  color: #0176db;
}

.navbar-nav.first .nav-link {
  font-size: 12px;
  color: var(--navbar-link-color);
  font-family: "ManropeRegular";
  margin-left: 10px;
  text-transform: uppercase;
}
.navbar-nav.first .nav-item.dropdown .dropdown-menu .dropdown-item {
  font-size: 18px;
  color: var(--navbar-link-color);
  font-family: "ManropeRegular";
}
.navbar-nav.first .nav-link.active {
  color: var(--navbar-active-color);
  font-family: "ManropeRegular";
}
.navbar-nav.first .nav-link:hover {
  color: var(--navbar-active-color);
  font-family: "ManropeRegular";
}
.dropdown-item.listNumberApp {
  font-size: 12px !important;
}
.dropdownMenuNumberApp li {
  padding: 8px 0px;
}

.navbar-nav.first .nav-item.dropdown .dropdown-menu .dropdown-item.active,
.navbar-nav.first .nav-item.dropdown .dropdown-menu .dropdown-item:hover {
  color: var(--navbar-active-color);
  font-family: ManropeExtraBold;
}
.full_width_nav {
  width: 14%;
}
.back_color_trans {
  background-color: transparent !important;
  border: 1px solid transparent;
}
.back_color_trans1 {
  border: 1px solid #424242b0 !important;
  backdrop-filter: blur(8px);
  border-radius: 10px;
  background: transparent;
  backdrop-filter: blur(8px);
  margin-top: 10px;
}
.navbar-full .dropdown-menu {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
}
.navbar-toggler {
  border: none;
}
.navbar-full .dropdown,
.navbar-full .navbar-nav .nav-item.dropdown {
  position: static !important;
}
.modal-body .service_modal {
  margin-top: 10px;
  border-radius: 13px;
  border: 1px solid #343434 !important;
  background: rgba(0, 3, 9, 0.52) !important;
  backdrop-filter: blur(15px);
}
.modal_navbar_p {
  color: var(--text-light-gray);
  font-size: 11px;
  font-family: "ManropeRegular";
}
.modal-body {
  padding: 0 !important;
}


.modal_navbar_p1 {
  font-size: 13px;
}
.home_paragrpgh_1 {
  font-size: 15px;
  font-family: "ManropeRegular";
  color: rgba(255, 255, 255, 1);
  width: 60%;
}
.home_h3 {
  font-size: 28px;
}
@media screen and (max-width: 768px) {
  .full_width_nav {
    width: 100%;
  }
}
.home_paragrpgh {
  font-size: 15px;
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}
.home_h3 {
  font-family: ManropeMedium;
  color: var(--navbar-link-color);
}

.home_btn {
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 30px;
  background-color: var(--navbar-link-color);
  font-family: ManropeBold;
  font-size: 13px;
  color: var(--home-btn-color);
  border: 1px solid transparent;
}
.home_btn:hover {
  background-color: transparent;
  color: var(--navbar-link-color);
  border: 1px solid var(--navbar-link-color);
}
.home_btn1 {
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 30px;
  background-color: var(--navbar-link-color);
  font-family: ManropeBold;
  font-size: 13px;
  color: var(--home-btn-color);
  border: 1px solid transparent;
}
.home_btn1:hover {
  background-color: transparent;
  color: var(--navbar-link-color);
  border: 1px solid var(--navbar-link-color);
}

@media screen and (max-width: 768px) {
  .home_btn1 {
    padding: 10px;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--navbar-link-color);
    font-family: ManropeBold;
    font-size: 10px;
    color: var(--home-btn-color);
    border: 1px solid transparent;
  }
  .home_btn1:hover {
    padding: 10px;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--navbar-link-color);
    font-family: ManropeBold;
    font-size: 10px;
    color: var(--home-btn-color);
    border: 1px solid transparent;
  }
}
@media screen and (max-width: 576px) {
  .home_h3 {
    font-size: 18px;
    font-family: ManropeMedium;
    color: var(--navbar-link-color);
  }
  .home_paragrpgh_1 {
    font-size: 10px;
    width: 100%;
  }
  .home_btn {
    padding: 10px;
    font-size: 13px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 30px;
    background-color: var(--navbar-link-color);
    font-family: ManropeBold;
    color: var(--home-btn-color);
    border: 1px solid transparent;
  }
  .home_paragrpgh {
    font-size: 11px;
  }
  .brand {
    width: 33.33% !important;
  }
  .headingSty4.HomeHeading {
    font-size: 17px !important;
  }

  .harnessing {
    padding: 5px !important;
    margin: 15px 24px !important;
  }
  .background_element_img {
    background-image: none !important;
  }
}

#video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.webPage_box4 {
  position: relative;
}
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; 
}
.footer_para {
  font-size: 12px;
  width: 85%;
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}
.footer_para_1:hover {
  color: var(--navbar-active-color) !important;
}
.footer_para_mail {
  font-size: 12px;
  width: 85%;
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}
.footer_para_mail:hover {
  color: var(--navbar-active-color) !important;
}
.heading_decoration_none {
  font-size: 12px;
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}
.footer_para_1 {
  font-size: 12px;
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}
.footer_para_2:hover {
  color: var(--navbar-active-color);
}
.footer_para_2 {
  font-size: 12px;
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}
.circle-div {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid var(--navbar-link-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--navbar-link-color);
}
.circle-div:hover {
  background-color: var(--navbar-active-color);
}
.visiblty {
  visibility: hidden;
}
.footer_h5 {
  color: var(--navbar-link-color);
  font-size: 15px;
  font-family: "ManropeRegular";
}
.footer_hr {
  border-top: 1px solid var(--text-light-gray);
  margin: 0;
  padding: 0;
}
.bottom_footer_flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.footer_main_div {
  background-color: var(--footer-background-color);
}
.footer_para_4 {
  font-size: 12px;
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}
@media (max-width: 1215px) {
  .footer_para_space {
    height: 55px;
  }
}
@media (max-width: 768px) {
  .footer_para_2 {
    font-size: 11px;
    font-family: "ManropeRegular";
    color: var(--navbar-link-color);
  }
  .footer_para_mail {
    font-size: 11px;
  }
  .footer_para {
    font-size: 11px;
    width: 85%;
    font-family: "ManropeRegular";
    color: var(--navbar-link-color);
  }
  .footer_hr {
    margin-left: 12px;
    margin-right: 12px;
  }
}

.arrow_circle_slider {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--navbar-link-color);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--navbar-link-color);
}
.slider_h2 {
  font-size: 27px !important;
  font-family: UrbanistReg;
  color: var(--home-btn-color);
}
.slider_p {
  font-family: "ManropeRegular";
  font-size: 15px;
  color: var(--home-btn-color);
}

#slider-video {
  border-radius: 20px;
  width: 90%;
  height: 100%;
}
@media (max-width: 576px) {
  #slider-video {
    border-radius: 20px;
    width: 70%;
    height: 100%;
  }
  .slider_p {
    font-family: "ManropeRegular";
    font-size: 11px;
    color: var(--home-btn-color);
  }
  .footer_para_4 {
    font-size: 9px;
    font-family: "ManropeRegular";
    color: var(--navbar-link-color);
  }
}
.slider_background {
  background-image: url("./assets/images/slider_background.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
@media (max-width: 576px) {
  slider_background {
    background-size: contain;
  }
  #slider-video {
    border-radius: 20px;
    width: 100%;
  }
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: white;
  border: 2px solid #123777;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.carousel-indicators .active {
  background-color: #123777 !important;
  width: 35px !important;
  height: 7px !important;
  border-radius: 20px !important;
}

.about_main_div {
  background-image: url("./assets/images/BGAbout.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.aboutpage_h3.careerText {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.aboutpage_h3 {
  color: var(--navbar-link-color);
  font-family: UrbanistReg;
}
.aboutpage_para {
  color: var(--navbar-link-color);
  font-family: "ManropeRegular";
  font-size: 14px;
}
@media (max-width: 576px) {
  .aboutpage_h3 {
    color: var(--navbar-link-color);
    font-family: UrbanistReg;
    font-size: 29px !important;
  }
  .aboutpage_para {
    color: var(--navbar-link-color);
    font-family: "ManropeRegular";
    font-size: 11px;
  }
}

.background_element_img {
  background-image: url("./assets/images/Homepage\ BG\ Elements.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.main_div_conti {
  background-image: url("./assets/images/BGDotsMap.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.contact_map_div {
  background-image: url("./assets/images/BGMap.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.contact_main_div {
  background-image: url("./assets/images/BGContact.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.main_div_conti2 {
  background-color: #f7fcff;
}
.contactpage_h3_1 {
  font-size: 15px;
  font-family: "UrbanistReg";
}
.contact_form {
  font-family: ManropeRegular;
  position: relative;
  background-color: #fdfdfd;
  border-radius: 20px;
  padding: 10px 10px;
  box-shadow: 0px 14.800000190734863px 48.75777816772461px 0px #0000001a;
  z-index: 12;
}
.contactpage_p_1 {
  color: #2b2b2b;
  font-family: ManropeRegular;
  font-weight: 400;
  line-height: normal;
  font-size: 10px;
}
.contactpage_p_2 {
  color: #303030;
  font-family: ManropeRegular;
  font-weight: 400;
  line-height: normal;
  font-size: 13px;
}
.contact_main_div2 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}
.contacti_h3 {
  font-size: 27px;
  font-weight: 300;
  font-family: "UrbanistReg";
  color: #2b2b2b;
}
.maping {
  width: 100%;
  height: 160px;
  border-radius: 8px;
  margin-top: 6%;
}
.location_box_cont {
  border-radius: 9.769px;
  border: 2.016px solid rgba(246, 246, 246, 0.6);
  background: #fdfdfd;
  box-shadow: 0px 9.072px 29.886px 0px rgba(0, 0, 0, 0.1);
  display: block;
}
.activeBtn.careerDetailActiveBtn {
  width: 35px;
  height: 35px;
}
.inactiveBtn.careerDetailActiveBtn {
  width: 35px;
  height: 35px;
}
.contact_h3_align {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 75%;
}
.main_div_conti3 {
  height: 750px;
}

.global_contacti {
  position: absolute;
  top: 70%;
}

@media only screen and (min-width: 1950px) and (max-width: 4000px) {
  .main_div_conti3 {
    height: 1200px;
  }

  .global_contacti {
    position: absolute;
    top: 81%;
  }
}
@media only screen and (min-width: 769px) and (max-width: 993px) {
  .main_div_conti3 {
    height: 850px;
  }
  .global_contacti {
    display: none;
  }
}
@media only screen and (min-width: 573px) and (max-width: 768px) {
  .main_div_conti3 {
    height: 850px;
  }
}
@media (max-width: 768px) {
  .contact_h3_align {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .main_div_conti {
    background-color: #f7fcff;
    width: 100%;
    background-image: none;
  }
}
@media (max-width: 576px) {
  .contact_main_div {
    background-image: url("./assets/images/BGDelete.png");
    background-position: center;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .contactpage_h3_1 {
    font-size: 14px;
  }
  .contactpage_p_1 {
    font-size: 9px;
  }
  .contact_main_div2 {
    box-shadow: none;
    background-color: transparent;
  }
  .maping {
    width: 90%;
    height: 130px;
    border-radius: 6px;
    margin-top: 0%;
  }
  .contact_h3_align {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .contactpage_p_2 {
    display: none;
  }
  .contacti_h3.contactPage {
    font-size: 19px !important;
  }
  .contacti_h3 {
    font-size: 13px;
    margin-bottom: 13%;
    text-align: center;
  }
  .contact_form {
    font-family: ManropeRegular;
    position: relative;
    background-color: #fdfdfd;
    border-radius: 10px;
    box-shadow: 0px 14.800000190734863px 48.75777816772461px 0px #0000001a;
    z-index: 12;
  }
  .checkbox_lable {
    font-size: 14px !important;
    color: #4e4e4e !important;
    font-weight: 500 !important;
  }
  .contactPage_h3.otherPositions,
  .contactPage_h3.aboutJob {
    font-size: 29px !important;
    font-weight: 300;
    font-family: "UrbanistReg";
  }
  .careers_h3_2 {
    color: #2b2b2b;
    font-family: UrbanistSemiBold !important;
    font-size: 22px;
  }
  .inactiveBtn {
    width: 40px !important;
    height: 40px !important;
  }
  .activeBtn {
    width: 40px !important;
    height: 40px !important;
  }
  .main_div_conti3 {
    height: auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .contactpage_h3_1 {
    font-size: 13px !important;
    font-family: "UrbanistReg";
  }
}

.contactPage_p {
  font-size: ManropeRegular;
  font-size: 15px;
}
.contactPage_h3.otherPositions,
.contactPage_h3.aboutJob {
  font-size: 38px;
  font-weight: 300;
  font-family: "UrbanistReg";
}
.contactPage_h3 {
  font-size: 32px;
  font-weight: 300;
  color: #2b2b2b;
  font-family: "UrbanistReg";
}
.contacti {
  height: 500px;
  background-color: #e7f2ff;
}
.main_contacti_input input {
  border: none !important;
  outline: none !important;
  padding-left: 5px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  display: block !important;
  width: 100% !important;
  font-size: 1rem !important;
  margin: 0 !important;
  color: #4e4e4e !important;
  border-radius: var(--bs-border-radius) !important;
  background-color: transparent !important;
}
.main_contacti_input input::placeholder {
  color: #4e4e4e !important;
  font-size: 14px;
  font-family: ManropeRegular;
  font-weight: 400;
}
.main_number_input input::placeholder {
  color: #4e4e4e !important;
  font-size: 14px;
  font-family: ManropeRegular;
  font-weight: 400;
}
.select_conta {
  width: 35%;
}
.main_contacti_input input:focus {
  border: none !important;
  outline: none !important;
  padding-left: 5px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  display: block !important;
  width: 100% !important;
  font-size: 1rem !important;
  margin: 0 !important;
  color: var(--bs-body-color) !important;
  border-radius: var(--bs-border-radius) !important;
  background-color: transparent !important;
}

.main_number_input input {
  border: none !important;
  outline: none !important;
  padding: 0px !important;
  display: block !important;
  width: 100% !important;
  font-size: 1rem !important;
  margin: 0 !important;
  color: var(--bs-body-color) !important;
  border-radius: var(--bs-border-radius) !important;
  background-color: transparent !important;
}
.main_number_input input:focus {
  border: none !important;
  outline: none !important;
  padding: 0px !important;
  display: block !important;
  width: 100% !important;
  font-size: 1rem !important;
  margin: 0 !important;
  color: var(--bs-body-color) !important;
  border-radius: var(--bs-border-radius) !important;
  background-color: transparent !important;
}
.country_bottom_padding {
  padding-bottom: 6px;
}
.top_margin_input {
  margin-top: 10%;
}
.dropdownNumberAppConta {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 12px;
}

.dropdownSelectiApp.show {
  border: 1px solid transparent !important;
  color: var(--bs-body-color);
}
.dropdownSelectiApp {
  width: 100%;
  padding-left: 3px;
  background-color: transparent !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent !important;
  border-radius: var(--bs-border-radius);
}

.form-group .nomi {
  display: none; 
  border-radius: none !important;
}

.form-group .nomi + label:before {
  content: "";
  background-color: transparent !important;
  border: 2px solid #848484;
  border-radius: 5% !important;
  padding: 8px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px !important;
}

.form-group .nomi:checked + label:before {
  background-color: #0a76db !important; 
  border: 2px solid #0a76db !important;
}
.border_bottom_input {
  border-bottom: 1px solid #bababa;
}

.checkbox_lable {
  color: #4e4e4e !important;
  font-size: 14px;
  font-family: ManropeRegular;
  font-weight: 400;
}

.form-group .nomi:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5.5px;
  left: 8px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.top_margin_btn {
  margin-top: 20%;
  margin-bottom: 6%;
}

.dots_contacti {
  position: absolute;
  top: 75%;
  left: 80%;
}
@media (max-width: 1024px) {
  .contacti {
    height: 850px;
    background-color: #e7f2ff;
  }
}
@media (max-width: 768px) {
  .contacti {
    height: 1250px;
    background-color: #e7f2ff;
  }
  .dots_contacti {
    display: none;
  }
  .global_contacti {
    display: none;
  }
}
@media (max-width: 576px) {
  .select_conta {
    width: 20%;
  }
  .dropdownNumberAppConta {
    width: 100%;
    padding-left: 3px;
    justify-content: start;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 12px;
  }
  .contacti {
    height: 1520px;
    background-color: #e7f2ff;
  }
  .location_box_cont {
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
  }
  .top_margin_input {
    margin-top: 10%;
  }
  .top_margin_btn {
    margin-top: 15%;
    margin-bottom: 6%;
  }
}
.main_map_div {
  height: 125vh;
}

.legal_main_div {
  background-image: url("./assets/images/BGPrivacy.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.legal_h3 {
  color: var(--text-light-black);
  font-family: UrbanistReg;
  font-size: 30px;
}
.legal_h5 {
  color: var(--text-light-black);
  font-family: "UrbanistReg";
  font-size: 24px;
}
.legal_p {
  color: var(--text-light-black);
  font-family: ManropeMedium;
  font-weight: 400;
  font-size: 15px;
}
@media screen and (max-width: 768px) {
  .legal_main_div {
    background-image: url("./assets/images/BGDelete.png");
    background-position: center;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .legal_h3 {
    color: var(--text-light-black);
    font-family: UrbanistReg;
    font-size: 24px;
  }
}

.terms_main_div {
  background-image: url("./assets/images/BGPrivacy.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.terms1_main_div {
  background-image: url("./assets/images/BGLegal.png");
  background-position: center;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.certi_main_div {
  background-image: url("./assets/images/BGAbout1.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.privacy_main_div {
  background-image: url("./assets/images/BGPrivacy.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 576px) {
  .privacy_main_div {
    background-image: url("./assets/images/BGDelete.png");
    background-position: center;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .terms_main_div {
    background-image: url("./assets/images/BGDelete.png");
    background-position: center;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.certification_back {
  background-image: url("./assets/images/BGPrivacy.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.legal1_main_div {
  background-image: url("./assets/images/BGLegal.png");
  background-position: center;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.divider2 {
  height: 2px;
  background-color: #0176db;
  width: 20px;
}
.careersHero {
  background-color: #e7f2ff;
}

.careers_h3 {
  color: #0a76db;
  font-family: "UrbanistReg";
  font-size: 22px;
}
.careers_h3.careerDetailHeading {
  color: #0a76db;
  font-family: "UrbanistBold";
  font-size: 30px;
}
.career_btn {
  padding: 6px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 30px;
  background-color: #0a76db;
  font-family: ManropeBold;
  color: white;
  border: 2px solid #0a76db;
  box-shadow: 0px 19px 26px -9px #0000002b;
}
.career_btn:hover {
  color: #0a76db;
  border: 2px solid #0a76db;
  background-color: white;
}

.careerSubHeadingFontSize {
  font-size: 18px !important;
}
.careerMobileHeadingBorder {
  margin-bottom: 2px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.CareerDetailMobileViewLine {
  background-color: #0176db;
  padding: 1.3px;
  border-radius: 18px;
  width: 65px;
}
.careers_h3_1 {
  color: #2b2b2b;
  font-family: "ManropeSemiBold" !important;
  font-size: 25px;
}
.careers_h3_105 {
  color: #2b2b2b;
  font-family: "ManropeSemiBold" !important;
  font-size: 25px;
}
.career_main_box {
  box-shadow: 0px 4px 22px 0px #00000017;
  background-color: #fff;
}
.career_p_1 {
  font-family: "UrbanistBold";
  color: #0a76db;
  font-size: 22px;
}
.career_p_2 {
  font-family: "ManropeRegular";
  color: #2b2b2b;
  font-size: 11px;
}
.careers_h3_2 {
  color: #2b2b2b;
  font-family: UrbanistBold;
  font-size: 17px;
}
.border_right_career {
  border-right: 2px solid #f1f1f1;
}
.navbar-nav.first .nav-link:hover .ChevronDown {
  color: var(--navbar-active-color) !important;
}
.circle_career:hover {
  background-color: #0176db;
}
.circle_career {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2b2b2b;
  color: #2b2b2b;
}
.circle_career1 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2b2b2b;
  color: #2b2b2b;
}
.inactiveBtn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: transparent;
  color: #2b2b2b;
}
.activeBtn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: transparent;
  background-color: #0176db;
}
.career2_main_div {
  background-image: url("./assets/images/BGCareer2.jpeg");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.career_main_div {
  background-image: url("./assets/images/BGCareer2.jpeg");
  background-position: right;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.btn_carrers {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
@media screen and (max-width: 576px) {
  .btn_carrers {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .careers_h3.careerDetailHeading {
    color: #0a76db;
    font-family: "UrbanistBold";
    font-size: 22px !important;
  }
  .careerSubHeadingFontSize {
    font-size: 14px !important;
  }
}

.delete_main_div {
  background-image: url("./assets/images/BGPrivacy.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.form_control_delete {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  color: var(--bs-body-color);
  appearance: none;
  background-clip: padding-box;
  border-radius: var(--bs-border-radius);
  background-color: #f7f7f7 !important;
  border: 2px solid #ebebeb;
  border-radius: 10px;
  outline: transparent;
}
.form-select {
  background-color: #f7f7f7 !important;
  border: 2px solid #ebebeb;
}
.form_textarea_delete {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  color: var(--bs-body-color);
  appearance: none;
  background-clip: padding-box;
  background-color: #f7f7f7 !important;
  border: 2px solid #ebebeb;
  border-radius: 10px;
  padding-left: 15px !important;
  padding-top: 10px !important;
}
.form_textarea_delete::placeholder {
  font-size: 12px;
}
.form_textarea_delete:focus {
  display: block;
  outline: none !important;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  color: var(--bs-body-color);
  appearance: none;
  background-clip: padding-box;
  background-color: #f7f7f7 !important;
  border: 2px solid #0176db;
  border-radius: 10px;
  padding-left: 15px !important;
  padding-top: 10px !important;
}
.dele_btn {
  padding: 10px;
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 30px;
  background-color: #0a76db;
  font-family: ManropeBold;
  color: white;
  border: 2px solid #0a76db;
}
.dele_btn:hover {
  padding: 10px;
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 30px;
  color: #0a76db;
  border: 2px solid #0a76db;
  font-family: ManropeBold;
  background-color: white;
}
.deletePage_h3 {
  font-size: 30px;
  font-weight: 300;
  max-width: 30% !important;
  font-family: "UrbanistReg";
}
@media screen and (max-width: 768px) {
  .deletePage_h3 {
    font-size: 30px;
    font-weight: 300;
    max-width: 50% !important;
    font-family: "UrbanistReg";
  }
}
@media screen and (max-width: 576px) {
  .delete_main_div {
    background-image: url("./assets/images/BGDelete.png");
    background-position: center;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .dele_btn {
    font-size: 13px !important;
    padding: 10px 25px !important;
  }
  .frameWrapper {
    display: none;
  }
  .frameWrapper2 {
    display: none;
  }
  .deletePage_h3 {
    font-size: 16px;
    max-width: 100% !important;
    font-family: "UrbanistReg";
  }
  .googleMapLocation {
    width: 100%;
    height: 250px !important;
    position: relative;
  }
  .careers_h3.careerDetailHeading {
    font-weight: 700;
    text-align: center;
  }
  .career_btn {
    text-align: center;
  }
  .career_btn.careerDetails {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    background-color: #0085e6 !important;
    font-family: ManropeBold;
    font-size: 13px !important;
    color: white;
    border: 2px solid #0085e6 !important;
    box-shadow: 0px 19px 26px -9px #0000002b;
  }
  .carrierDetails {
    background-image: none !important;
  }
  .aboutTheJobCareerDetail {
    background-image: url("./assets/images/BGCareerMobileScreen.png");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: 39px;
    width: 100%;
    background-size: 20% 10% !important;
    background-repeat: no-repeat;
  }
  .sliderOtherPositions {
    background-image: none !important;
  }
  .borderTopShareJob {
    border-top: 2px solid #f1f1f1;
  }
  .career_p_1 {
    font-family: "UrbanistSemiBold";
    color: #0a76db;
    font-size: 22px;
  }
  .jobsCard.careerDetailotherPositions {
    height: 230px !important;
  }
  .pragraph_Sty_para.homeScreen {
    font-size: 9px !important;
    font-weight: 500;
    font-family: "ManropeRegular";
  }
}


.contact_page_foot {
  margin-top: 20%;
}
@media only screen and (min-width: 768px) and (max-width: 792px) {
  .deletePage_h3 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 1200px) {
  .deleteMydataForm {
    margin-top: 0%;
    width: 100%;
    position: absolute;
  }
  .contact_page_foot {
    margin-top: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .deleteMydataForm {
    margin-top: 3%;
    width: 100%;
    position: absolute;
  }
}
@media (max-width: 767px) {
  .delete_foot {
    margin-top: 190px;
  }
  .deleteMydataForm {
    width: 100%;
    position: absolute;
    top: 25%;
  }
  .contact_page_foot {
    margin-top: 140%;
  }
  .border_right_career {
    border-right: none;
  }
}

@media only screen and (max-width: 576px) {
  .delete_foot {
    margin-top: 89px !important;
  }
  .PersonalDataInDeleteMyData {
    margin: 7% 0px !important;
  }
  .deleteMydataForm {
    width: 100%;
    position: absolute;
    top: 14%;
  }
  .contact_page_foot {
    margin-top: 10%;
  }
}

@media screen and (max-width: 769px) {
  .carousel-control-next {
    display: none;
  }
  .carousel-control-prev {
    display: none;
  }
  .carousel-indicators {
    display: none;
  }
}

.mobileLogoScreen {
  width: 30% !important;
  height: 30px;
}

.allbrandlogo {
  width: 100% !important;
  height: auto;
}
@media (max-width: 768px) {
  .allbrandlogo {
    width: 90% !important;
    height: auto;
  }
}

@media (min-width: 576px) {
  .disp_text {
    display: none;
  }
}
@media (min-width: 769px) {
  .left_right_mobile {
    display: none;
  }
}
.modal.fade.show {
  background-color: transparent !important;
}

.searchIcon {
  color: var(--text-light-gray) !important;
}
.countryCodeSeleted {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-weight: normal !important;
}

.selectCountry.main_number_input input::placeholder {
  color: var(--text-light-gray) !important;
}


.modal-backdrop.show {
  opacity: 0 !important;
}
.cursor_pointer {
  cursor: pointer;
}



.Our_Team_box {
  border: 6px solid#EEEEEE;
  position: relative;
  border-radius: 14px;
  box-shadow: 0px 18px 59.29999923706055px 0px #00000026;
  height: 100%;
}
.team_images {
  width: 100%;
  height: auto;
}
.Team_Details_box {
  position: absolute;
  background-color: white;
  border-radius: 6px;
  width: 90%;
  bottom: 5%;
  left: 5%;
}

.Team_Details_box h6 {
  font-family: UrbanistBold !important;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 16px;
}

.Team_Details_box p {
  font-family: ManropeBold !important;
  text-align: center;
  font-size: 9px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #06558c;
}

.heading_Sty_har {
  font-size: 35px;
  font-family: UrbanistReg;
  font-weight: 300;
  width: 75%;
}

.pragraph_Sty_para {
  font-size: 15px;
  font-weight: 500;
  font-family: "ManropeRegular";
}

.swati_Icon_bg {
  position: absolute;
  left: 2%;
  width: 70%;
}
.pragraphSty.whyChoose {
  font-size: 13px !important;
  font-family: ManropeRegular;
  font-weight: 500;
}
.homeFeature_p_2 {
  font-family: Questrial;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
}
.homeFeature_p_8 {
  font-family: ManropeRegular;
  font-size: 9.5px;
  height: 50px;
  font-weight: 300;
  color: #a3a3a3;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .contactpage_p_1 {
    font-size: 9.8px !important;
  }
}

@media (min-width: 2000px) {
  .Team_Details_box p {
    font-size: 16px;
  }
  .Team_Details_box h6 {
    font-size: 26px;
  }
  .swati_Icon_bg {
    width: 80%;
  }
  .heading_Sty_har {
    font-size: 20px;
    font-family: UrbanistReg;
    font-weight: 300;
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .Team_Details_box p {
    font-size: 7px;
  }
  .Team_Details_box h6 {
    font-size: 13px;
  }
}
@media (max-width: 1024px) {
  .swati_Icon_bg {
    width: 100%;
  }
  .heading_Sty_har {
    font-size: 20px;
    font-family: UrbanistReg;
    font-weight: 300;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .swati_Icon_bg {
    width: 100%;
  }
  .heading_Sty_har {
    font-size: 20px;
    font-family: UrbanistReg;
    font-weight: 300;
    width: 100%;
  }
}
@media (max-width: 576px) {
  .Team_Details_box {
    border-radius: 6px;
  }
  .Team_Details_box h6 {
    font-size: 11px;
  }

  .Team_Details_box p {
    font-size: 6px;
  }
  .swati_Icon_bg {
    width: 80%;
    position: absolute;
    left: 18%;
    width: 70%;
  }
  .heading2Sty.homePage {
    font-size: 16px !important;
    font-family: "UrbanistSemiBold" !important;
  }
  .pragraphSty.Innovation,
  .pragraphSty.transparency,
  .pragraphSty.whyChoose {
    font-size: 11px !important;
  }

  .heading_Sty_har {
    font-size: 17px !important;
    font-family: UrbanistReg;
    font-weight: 300;
    width: 100%;
    text-align: center !important;
  }
}
@media (max-width: 335px) {
  .pragraphSty.whyChoose {
    font-size: 9px !important;
  }
}
@media (min-width: 1201px) and (max-width: 1440px) {
  .heading_Sty_har {
    font-size: 25px;
  }
  .background_element_img {
    background-size: contain;
  }
}
@media (max-width: 1200px) {
  .heading_Sty_har {
    font-size: 23px;
  }
  .background_element_img {
    background-size: contain;
  }
}
.navbar-nav .nav-item:hover .dropdown-menu {
  display: block;
}

.estimate_h3 {
  font-size: 34px;
  font-weight: 300;
  font-family: "UrbanistReg";
  color: #2b2b2b;
  width: 75%;
}
.estimate_main_box {
  margin-left: 5%;
}
.estimate_main_div {
  background-image: url("./assets/images/BGEstimate.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.estimate_btn {
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 30px;
  background-color: #0a76db;
  font-family: ManropeBold;
  color: white;
  border: 2px solid #0a76db;
}
.estimate_btn:hover {
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 30px;
  color: #0a76db;
  border: 2px solid #0a76db;
  font-family: ManropeBold !important;
  background-color: white;
}
.esti_input input::placeholder {
  font-size: 12px;
  font-family: ManropeRegular;
}
.esti_input input {
  font-size: 12px;
  font-family: ManropeRegular;
}
.esti_input textarea {
  font-size: 12px;
  font-family: ManropeRegular;
}
.esti_input label {
  font-family: UrbanistBold !important;
}
.esti_input select {
  font-size: 12px;
  font-family: ManropeRegular;
}
.form-check .form-check-input1 {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid #2b2b2b;
}
.form-check .form-check-input1:checked {
  border-radius: 50px !important;
  width: 20px !important;
  height: 20px !important;
  margin-top: 0.25em !important;
  vertical-align: top !important;
  appearance: none !important;
  background-color: #0176db !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  border: 2px solid #0176db !important;
}
.form-check .form-check-input1:focus {
  border-radius: 50px !important;
  width: 20px !important;
  height: 20px !important;
  margin-top: 0.25em !important;
  vertical-align: top !important;
  appearance: none !important;
  background-color: #0176db !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  border: 2px solid #0176db !important;
}
.select_est {
  width: 45%;
  font-family: ManropeRegular;
}
.form_select_estimate {
  padding: 0.65rem 0.75rem !important;
  background-color: #f7f7f7 !important;
  border: 2px solid #ebebeb;
  border-radius: 10px;
}
.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.form-group label {
  position: relative;
  cursor: pointer;
}
.form-group label:before {
  content: "";
  width: 16px !important;
  height: 16px !important;
  background-color: transparent;
  border: 1.5px solid #000000;
  padding: 8px;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}
.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 7px;
  width: 5px;
  height: 10px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.estimat_Form {
  position: relative;
  background-color: #fdfdfd;
  border-radius: 20px;
  padding: 20px 20px;
  box-shadow: 0px 14.800000190734863px 48.75777816772461px 0px #0000001a;
  z-index: 12;
  margin-top: -25%;
}
.textColorYesAndNo {
  color: #929292;
  font-size: 15px !important;
}
@media (min-width: 1800px) {
  .estimat_Form {
    z-index: 12;
    margin-top: -16%;
    padding: 8px 8px;
  }
}
@media (min-width: 787px) and (max-width: 993px) {
  .estimat_Form {
    z-index: 12;
    margin-top: 5%;
    padding: 8px 8px;
  }
}
@media (max-width: 993px) {
  .estimate_main_box {
    margin-left: 0%;
  }
}
@media (max-width: 1200px) {
  .estimate_btn {
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 768px) {
  .estimat_Form {
    z-index: 12;
    margin-top: 5%;
    padding: 8px 8px;
  }
}
@media (max-width: 576px) {
  .estimat_Form {
    z-index: 12;
    margin-top: 5%;
    padding: 8px 8px;
  }
  .estimate_h3 {
    font-size: 20px;
    width: 100%;
  }
  .textColorYesAndNo {
    color: #929292 !important;
    font-size: 14px !important;
  }
  .form-group label:before {
    content: "";
    width: 16px !important;
    height: 16px !important;
    background-color: transparent;
    border: 1.5px solid #929292 !important;
    padding: 8px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
}
.dropdownNumberApp {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  background-color: #f7f7f7 !important;
  display: flex;
  align-items: center;
  border: 2px solid #ebebeb;
  border-radius: 12px;
}
.colorNumber {
  color: #2b2b2b !important;
  font-size: 14px;
  font-family: ManropeRegular !important;
}
.colorNumber2 {
  color: #2b2b2b !important;
  font-size: 12px;
  font-family: ManropeRegular !important;
}

.dropdownMenuNumberApp {
  padding: 7px;
  height: 150px;
  overflow-y: auto;
  width: 100%;
  background-color: #fdfdfd;
  position: absolute;
  top: 35px;
  overflow-x: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.country-name,
.dial-code {
  font-size: 12px;
}

.SearchInputCountry.form-control {
  border-color: none !important;
  outline: none !important;
  border: none !important;
  border-color: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  font-size: smaller;
}
.SearchInputCountry.form-control:active {
  border-color: none !important;
  outline: none !important;
  border: none !important;
  border-color: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.SearchInputCountry.form-control:focus {
  border-color: none !important;
  outline: none !important;
  border: none !important;
  border-color: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.dropdown-menu.dropdown_menu_margin {
  min-width: 20px !important;
}
.dropdown-menu.dropdown_menu_margin.dropdownMenuNumberApp.estimate {
  position: absolute;
  width: 330%;
}

.dropdown-menu.dropdown_menu_margin.dropdownMenuNumberApp.estimate input:focus {
  outline: none !important;
  border: none !important;
  background: transparent !important;
  padding: 0;
}
.dropdown-menu.dropdown_menu_margin.dropdownMenuNumberApp.estimate input {
  outline: none !important;
  border: none !important;
  background: transparent !important;
}
.dropdown-item.listNumberApp {
  padding: 5px !important;
  border-bottom: 1px solid #bababa;
}
.listNumberApp {
  border-bottom: 1px solid #bababa !important;
}
.dropdown-toggle1::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23666666%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5px;
  vertical-align: middle;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
}
.listselectservices {
  font-size: 12px !important;
}

.navbar-nav.first .nav-link:hover .ChevronDown {
  color: var(--navbar-active-color) !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.border_bottom_line {
  border-bottom: 3px solid rgba(232, 232, 232, 0.6) !important;
}

#chatCon {
  width: 26%;
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 13px;
}

input {
  background-color: white;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: inherit !important;
  transition: background-color 5000s ease-in-out 0s;
}

.pop {
  cursor: pointer;
  position: fixed;
  bottom: -10px;
  right: 20px;
  z-index: 9999;
}

#chatCon .chat-box {
  display: none;
  width: 100%;
  height: 445px;
  border-radius: 15px;
  background-color: white;
}

.chatbot_send {
  background-color: rgba(247, 247, 255, 1);
  border-radius: 10px 10px 10px 1px;
}

.chatbot_recieve {
  background-color: rgba(23, 45, 82, 1);
  border-radius: 10px 10px 1px 10px;
}

.chatbot_loading {
  background-color: rgba(247, 247, 255, 1);
  border-radius: 12px 12px 2px 12px;
}

.chatbt_send_p,
.chatbt_recieve_p {
  font-family: ManropeRegular;
  font-size: 13px;
}

.chatbt_send_p {
  color: rgba(0, 0, 0, 1);
}

.chatbt_recieve_p {
  color: white;
}

.chatbot_header {
  background-color: rgba(23, 45, 82, 1);
  border-radius: 14px 14px 0 0;
}

.chatbot_footer {
  border: 1px solid rgba(233, 233, 244, 1);
  border-radius: 10px;
  margin: 5%;
}

.chatbox_input {
  border: 1px solid transparent;
  outline: none;
  width: 100%;
}

.chatbox_input:focus {
  outline: none;
  border: 1px solid transparent;
  color: rgba(153, 153, 153, 1);
}

.chatbox_input::placeholder {
  font-family: ManropeRegular;
  font-size: 13px;
  color: rgba(153, 153, 153, 1);
}

#chatCon .pop {
  width: 100%;
  height: 25%;
  cursor: pointer;
}

#chatCon .pop p {
  text-align: right;
}

#chatCon .pop img {
  border-radius: 50%;
  width: 15%;
}
.loader {
  width: 40px;
  aspect-ratio: 2;
  --_g1: no-repeat radial-gradient(circle closest-side, #B5B4C6 90%, #0000);
  --_g2: no-repeat radial-gradient(circle closest-side, #172D52 90%, #0000); 
  background: 
    var(--_g1) 0% 50%,    
    var(--_g2) 50% 50%,   
    var(--_g1) 100% 50%;  
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

@media (max-width: 576px) {
  #chatCon {
    width: 90%;
    bottom: 65px;
    right: 15px;
  }

  #chatCon .chat-box {
    height: 430px;
  }

  #chatCon .pop img {
    border-radius: 50%;
    width: 20%;
  }

  .chatbot_footer {
    margin: 2%;
  }
}

/* Usman CSS */
